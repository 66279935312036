import styled from 'styled-components';
import CounterCard from './CounterCard';
import { useContext } from 'react';
import { AnalyticsContext } from 'contexts/analytics';
import { SessionContext } from 'contexts/session';
import { COLOR_CHART_STATUSES } from 'constants/analytics';
import { screenMd, screenSm } from 'constants/styles/breakpoints';
import { durationFromSecs } from 'utils/helpers';

const Container = styled.div<{ mobileOnly?: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: 10px;
  background-color: #f2f2f7;
  padding: ${({ mobileOnly }) => (mobileOnly ? '0' : '10px')};
  ${screenSm} {
    display: flex;
    flex-direction: row;
    flex-direction: column;
    padding: 15px;
    flex-wrap: nowrap;
    width: auto;
  }
`;

interface ICounterItem {
  key: string;
  counterLabel?: string;
  mobileCounterLabel?: string;
  counterTooltipLabel?: string;
  disabled?: boolean;
  percentageKey?: string;
  hideCounter?: boolean;
}

const AnalyticsCounters = ({
  displayCounters,
  mobileOnly,
}: {
  displayCounters: ICounterItem[];
  mobileOnly?: boolean;
}): JSX.Element => {
  const { counters, currentChart, fetching } = useContext(AnalyticsContext);
  const { hasSkillDive } = useContext(SessionContext);

  const getQuantity = (key, percentageKey?) => {
    const value = counters[currentChart][key];
    if (percentageKey) {
      const percentage = counters[currentChart][percentageKey];
      return `${percentage ? Math.round((value / percentage) * 100) : 0}%`;
    }
    return getValue(key, value);
  };

  const getLabel = (key, isPercentage, label?, mobileCounterLabel?) => {
    if (isPercentage) {
      return `${getQuantity(key)} ${label || key}`;
    }
    const currentLabel = label || key;
    return mobileOnly ? mobileCounterLabel || currentLabel : currentLabel;
  };

  const getValue = (key: string, value: number) => {
    switch (key) {
      case 'percentage':
        return value ? `${value}%` : '0%';
      case 'watched':
        return durationFromSecs(value * 60, 'hhmm').replace(' ', '');
      case 'skill_dive':
        return !hasSkillDive ? '-' : value;
      default:
        return value ? value : '0';
    }
  };

  const hideDot = (disabled: boolean) => {
    return disabled || mobileOnly;
  };

  return (
    <Container mobileOnly={mobileOnly}>
      {displayCounters.map(
        (
          {
            key,
            counterLabel,
            counterTooltipLabel,
            disabled,
            percentageKey,
            mobileCounterLabel,
            hideCounter,
          },
          index
        ) => {
          return (
            <>
              {!hideCounter && (
                <CounterCard
                  fetching={fetching}
                  key={key}
                  quantity={getQuantity(key, percentageKey)}
                  label={getLabel(key, !!percentageKey, counterLabel, mobileCounterLabel)}
                  tooltipLabel={counterTooltipLabel}
                  displayDot={!hideDot(disabled)}
                  order={index + 1}
                  color={
                    disabled
                      ? null
                      : COLOR_CHART_STATUSES[key.toUpperCase()].counter ??
                        COLOR_CHART_STATUSES[key.toUpperCase()].stroke
                  }
                  style={!counterLabel ? { textTransform: 'capitalize' } : {}}
                />
              )}
            </>
          );
        }
      )}
    </Container>
  );
};

export default AnalyticsCounters;
